import React, { useEffect, useState } from "react";
import { Button, Form, Input, Row, Col, message } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";

const EmailTemplate = ({ items }) => {
  const { title, subject, body, _id } = items;
  const [editorContent, setEditorContent] = useState("");
  const [subContent, setSubContent] = useState("");
  const [form] = Form.useForm();

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    ["link", "image", "video", "formula"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ];

  const modules = {
    toolbar: toolbarOptions,
    clipboard: {
      matchVisual: false,
    },
  };

  useEffect(() => {
    if (subject) {
      setSubContent(subject);
    }
  }, [subject]);

  useEffect(() => {
    if (body) {
      try {
        const updatedBody = JSON.parse(body);
        setEditorContent(updatedBody);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, [body]);

  const handleUpdate = async () => {
    try {
      const response = await axios.put(`/api/v2/admin/email-templates/${_id}`, {
        subject: subContent,
        body: JSON.stringify(editorContent),
      });

      message.success("Email template updated successfully!");
    } catch (error) {
      message.error("Failed to update the email template.");
    }
  };

  const getSubject = (event) => {
    setSubContent(event.target.value);
  };

  const getEmailMessage = (content) => {
    setEditorContent(content);
  };

  useEffect(() => {
    const toolbars = document.querySelectorAll(".ql-toolbar");
    toolbars.forEach((toolbar) => {
      toolbar.style.backgroundColor = "#D0EDF7";
    });
  }, []);

  return (
    <>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Form
            layout="vertical"
            form={form}
            style={{ maxWidth: "100%" }}
            onFinish={handleUpdate}
          >
            <Col span={24}>
              <Form.Item
                label={<span style={{ fontWeight: "bold" }}>{title}</span>}
              >
                <Input
                  value={subContent}
                  onChange={getSubject}
                  style={{ marginBottom: "10px" }}
                  required
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item style={{ marginBottom: "100px" }}>
                <ReactQuill
                  theme="snow"
                  value={editorContent}
                  onChange={getEmailMessage}
                  modules={{ toolbar: toolbarOptions }}
                  style={{ height: "200px" }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button htmlType="submit">Submit</Button>
              </Form.Item>
            </Col>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default EmailTemplate;
