import React, { useState } from "react";
import { Table, Input, Select } from "antd";
import dayjs from "dayjs";

const HistoryTable = ({ notificationHistoryList }) => {
  const [searchText, setSearchText] = useState({
    subject: "",
    body: "",
    first_name: "",
    last_name: "",
    recipientType: "",
    response: "",
    dateSent: "",
  });

  const handleSearch = (key, value) => {
    setSearchText((prev) => ({
      ...prev,
      [key]: value.toLowerCase(),
    }));
  };

  const filteredData = notificationHistoryList
    ?.map((item, index) => ({
      key: index,
      subject: item.title || "N/A",
      body: item.body || "N/A",
      recipientType: item.recipientType || "Unknown",
      first_name: item.recipient.first_name || "",
      last_name: item.recipient.last_name || "",
      response: item.status || "Pending",
      dateSent: item.createdAt
        ? dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss")
        : "N/A",
    }))
    .filter((item) =>
      Object.keys(searchText).every((key) =>
        item[key]?.toString().toLowerCase().includes(searchText[key])
      )
    );

  const columns = [
    {
      title: (
        <>
          <div style={{ fontWeight: 700, fontSize: "11px" }}>DATE SEND</div>
          <Input
            value={searchText.dateSent}
            onChange={(e) => handleSearch("dateSent", e.target.value)}
            placeholder=""
            size="small"
          />
        </>
      ),
      dataIndex: "dateSent",
      key: "dateSent",
      width: 160,
      render: (text) =>
        text !== "N/A" ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "N/A",
    },

    {
      title: (
        <>
          <div style={{ fontWeight: 700, fontSize: "11px" }}>FIRST NAME</div>
          <Input
            value={searchText.recipient}
            onChange={(e) => handleSearch("first_name", e.target.value)}
            placeholder=""
            size="small"
          />
        </>
      ),
      dataIndex: "first_name",
      key: "first_name",
      width: 100,
      ellipsis: true,
    },
    {
      title: (
        <>
          <div style={{ fontWeight: 700, fontSize: "11px" }}>LAST NAME</div>
          <Input
            value={searchText.last_name}
            onChange={(e) => handleSearch("last_name", e.target.value)}
            placeholder=""
            size="small"
          />
        </>
      ),
      dataIndex: "last_name",
      key: "last_name",
      width: 100,
      ellipsis: true,
    },

    {
      title: (
        <>
          <div style={{ fontWeight: 700, fontSize: "11px" }}>TITLE</div>
          <Input
            value={searchText.subject}
            onChange={(e) => handleSearch("subject", e.target.value)}
            placeholder=""
            size="small"
          />
        </>
      ),
      dataIndex: "subject",
      key: "subject",
      ellipsis: true,
    },
    {
      title: (
        <>
          <div style={{ fontWeight: 700, fontSize: "11px" }}>BODY</div>
          <Input
            value={searchText.body}
            onChange={(e) => handleSearch("body", e.target.value)}
            size="small"
          />
        </>
      ),
      dataIndex: "body",
      key: "body",
    },
    {
      title: (
        <>
          <div style={{ fontWeight: 700, fontSize: "11px" }}>
            RECIPIENT TYPE
          </div>
          <Input
            value={searchText.recipientType}
            onChange={(e) => handleSearch("recipientType", e.target.value)}
            placeholder=""
            size="small"
          />
        </>
      ),
      dataIndex: "recipientType",
      key: "recipientType",
      width: 100,
      render: (text) => capitalizeText(text),
    },
    {
      title: (
        <>
          <div style={{ fontWeight: 700, fontSize: "11px" }}>STATUS</div>
          <Input
            value={searchText.response}
            onChange={(e) => handleSearch("response", e.target.value)}
            placeholder=""
            size="small"
          />
        </>
      ),
      dataIndex: "response",
      key: "response",
      width: 70,
      render: (text) => capitalizeText(text),
    },
  ];

  const capitalizeText = (text) => {
    return text
      ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
      : text;
  };

  return (
    <Table
      className="special-table users-manager-table"
      columns={columns}
      dataSource={filteredData}
      pagination={{ pageSize: 10 }}
    />
  );
};

export default HistoryTable;
