import axios from "axios";
import { message as AntdAlert } from "antd";
import { put, takeEvery } from "redux-saga/effects";
import * as actions from "../actions";
import * as constants from "../constants";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import { errorHandler } from "../../../../components/unauthorizeHandler";

export function* handleFetchSubObservations({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v2/admin/sub-observations`
    );
    const { success } = response.data;
    if (success) {
      yield put(
        actions.fetchSubObservationsSuccess(response.data.data.subObservations)
      );
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchSubObservationsError());
    AntdAlert.error("Something went wrong!");
    console.log(error);
  }
}

export function* handleUpdateSubObservation({ data }) {
  // const { id, name, detail, deleted_status, ...rest } = data;
  const { id, name } = data;

  try {
    const fd = new FormData();
    fd.append("name", name);
    // fd.append('detail', detail);
    fd.append("sub_observation_id", id);
    fd.append("id", id);
    // fd.append('deleted_status', deleted_status);
    // if (rest?.icon) {
    //   fd.append('icon', rest.icon);
    // }

    const response = yield axios.put(
      `${baseURL}/api/v2/admin/sub-observations`,
      fd
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(
        actions.updateSubObservationsSuccess(response.data.data.subObservation)
      );
    } else {
      AntdAlert.error(message);
      yield put(actions.updateSubObservationsError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateSubObservationsError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleDeleteSubObservations({ data }) {
  const { id } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v2/sub-observations/${id}`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteSubObservationsSuccess(id));
    } else {
      AntdAlert.error(message);
      yield put(actions.deleteSubObservationsError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteSubObservationsError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleCreateSubObservation({ data }) {
  const { name, description, icon, deleted_status } = data;
  try {
    const fd = new FormData();
    fd.append("name", name);
    fd.append("description", description);
    fd.append("icon", icon);
    fd.append("deleted_status", "active");
    const response = yield axios.post(
      `${baseURL}/api/v2/admin/sub-observations`,
      fd
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(
        actions.createSubObservationsSuccess(response.data.data.subObservation)
      );
    } else {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createSubObservationsError(data.id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
    yield put(actions.createSubObservationsError());
  }
}

export function* handleUpdateSubObservationOrder({ data }) {
  const fd = new FormData();
  data.map((el, i) => fd.append(`subObservations[${el.id}]`, i + 1));

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v2/sub-observations/sort`,
      fd
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateSubObservationOrderSuccess(data));
    } else {
      AntdAlert.error(message);
      yield put(actions.updateSubObservationOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateSubObservationOrderError());
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* watcherSubObservationsSaga() {
  yield takeEvery(constants.FETCH_SUB_OBSERVATIONS, handleFetchSubObservations);
  yield takeEvery(
    constants.CREATE_SUB_OBSERVATIONS,
    handleCreateSubObservation
  );
  yield takeEvery(
    constants.UPDATE_SUB_OBSERVATIONS,
    handleUpdateSubObservation
  );
}
