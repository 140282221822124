import React, { useEffect } from "react";
import { Col, Row, Divider, List, Typography, Form, Button } from "antd";
import EmailTemplate from "./EmailTemplate";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleOutlined } from "@ant-design/icons";

const microtitles = [
  "{student_name}",
  "{tenant_name}",
  "{tutor_name}",
  "{guardian_name}",
  "{acceptLink}",
  "{rejectLink}",
];

const EmailForm = () => {
  const dispatch = useDispatch();
  const { allEmails } = useSelector((state) => state.emailsManagerReducer);

  const { email_templates = [], template_macros = [] } = allEmails;

  useEffect(() => {
    dispatch({ type: "FETCH_EMAILS_TEMPLATES_LIST" });
  }, [dispatch]);

  return (
    <>
      <Row align="middle" gutter={[16, 24]}>
        <Col span={24}>
          <div className="heading-col">
            <span className="heading-span">Email Templates</span>
          </div>
        </Col>

        <Col span={24} style={{ paddingLeft: "20px" }}>
          <List
            dataSource={microtitles}
            renderItem={(item) => (
              <List.Item
                style={{
                  fontSize: "15px",
                  padding: "0",
                  borderBottom: "none",
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                <CheckCircleOutlined
                  style={{ color: "#1890ff", fontSize: "16px" }}
                />
                <Typography.Text>{item}</Typography.Text>
              </List.Item>
            )}
          />
        </Col>
        <Col span={24}>
          {email_templates.map((items) => {
            return <EmailTemplate key={items._id} items={items} />;
          })}
        </Col>
      </Row>
    </>
  );
};

export default EmailForm;
