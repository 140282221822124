import * as constants from "./constants";

const initialState = {
  allEmails: {
    email_templates: [],
    template_macros: [],
    // notificationHistoryList: [],
  },
  error: null,
};
const emailsManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_EMAILS_TEMPLATES_LIST_SUCCESS:
      return {
        ...state,
        allEmails: action.payload,
      };

    case constants.FETCH_EMAILS_TEMPLATES_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case constants.SEND_EMAIL:
      return {
        ...state,
        // createUserLoading: true,
      };

    case constants.FETCH_EMAILS_HISTORY_SUCCESS:
      return {
        ...state,
        emailsHistoryList: action.payload,
      };

    default:
      return state;
  }
};

export default emailsManagerReducer;
