import * as constants from "./constants";

export const setUserDetailsIsEdit = (data) => {
  return {
    type: constants.USER_DETAILS_IS_EDIT,
    data: data,
  };
};

export const setUserPermissionsIsEdit = (data) => {
  return {
    type: constants.SET_USER_PERMISSIONS_IS_EDIT,
    data,
  };
};

export const createUser = (data) => {
  return {
    type: constants.CREATE_USER,
    data: data,
  };
};

export const createUserSuccess = (data) => {
  return {
    type: constants.CREATE_USER_SUCCESS,
    data: data,
  };
};

export const createUserError = () => {
  return {
    type: constants.CREATE_USER_ERROR,
  };
};

export const updateUserPermissions = (data) => {
  return {
    type: constants.UPDATE_USER_PERMISSIONS,
    data: data,
  };
};

export const updateUserPermissionsSuccess = (data) => {
  return {
    type: constants.UPDATE_USER_PERMISSIONS_SUCCESS,
    data: data,
  };
};

export const updateUserPermissionsError = () => {
  return {
    type: constants.UPDATE_USER_PERMISSIONS_ERROR,
  };
};

export const fetchUser = (data) => {
  return {
    type: constants.FETCH_USER,
    data: data,
  };
};

export const fetchUserSuccess = (data) => {
  return {
    type: constants.FETCH_USER_SUCCESS,
    data,
  };
};

export const fetchUserError = () => {
  return {
    type: constants.FETCH_USER_ERROR,
  };
};

export const updateUser = (data) => {
  return {
    type: constants.UPDATE_USER,
    data: data,
  };
};

export const updateUserSuccess = (data) => {
  return {
    type: constants.UPDATE_USER_SUCCESS,
    data: data,
  };
};

export const updateUserError = () => {
  return {
    type: constants.UPDATE_USER_ERROR,
  };
};

export const updateUserSettings = (data) => {
  return {
    type: constants.UPDATE_USER_SETTINGS,
    data: data,
  };
};

export const updateUserSettingsSuccess = (data) => {
  return {
    type: constants.UPDATE_USER_SETTINGS_SUCCESS,
    data: data,
  };
};

export const updateUserSettingsError = () => {
  return {
    type: constants.UPDATE_USER_SETTINGS_ERROR,
  };
};

export const fetchUserDetailsUtils = () => {
  return {
    type: constants.FETCH_USERS_UTIL_DATA,
  };
};

export const fetchUserDetailsUtilsSuccess = (data) => {
  return {
    type: constants.FETCH_USERS_UTIL_DATA_SUCCESS,
    data,
  };
};

export const fetchAllUserList = (data) => {
  return {
    type: constants.FETCH_ALL_USERS_LIST,
    data,
  };
};

export const fetchAllUsersListSuccess = (data) => {
  return {
    type: constants.FETCH_ALL_USERS_LIST_SUCCESS,
    data,
  };
};

export const fetchAllUsersListError = () => {
  return {
    type: constants.FETCH_ALL_USERS_LIST_ERROR,
  };
};

export const fetchAllUsers = (data) => {
  return {
    type: constants.FETCH_ALL_USERS,
    data,
  };
};

export const fetchAllUsersSuccess = (data) => {
  return {
    type: constants.FETCH_ALL_USERS_SUCCESS,
    data,
  };
};

export const fetchAllUsersError = () => {
  return {
    type: constants.FETCH_ALL_USERS_ERROR,
  };
};

export const deleteUser = (data) => {
  return {
    type: constants.DELETE_USER,
    data,
  };
};

export const deleteUserSuccess = (data) => {
  return {
    type: constants.DELETE_USER_SUCCESS,
    data,
  };
};

export const deleteUserError = (data) => {
  return {
    type: constants.DELETE_USER_ERROR,
    data,
  };
};

export const saveDepartment = (data) => {
  return {
    type: constants.SAVE_DEPARTMENT,
    data,
  };
};

export const saveDepartmentSuccess = (data) => {
  return {
    type: constants.SAVE_DEPARTMENT_SUCCESS,
    data,
  };
};

export const saveDepartmentError = (data) => {
  return {
    type: constants.SAVE_DEPARTMENT_ERROR,
    data,
  };
};

export const userDetailCleanupFunc = () => {
  return {
    type: constants.USER_DETAIL_CLEANUP_FUNC,
  };
};

export const sendDetailsToUser = (data) => {
  return {
    type: constants.SEND_DETAILS_TO_USER,
    data: data,
  };
};

export const sendDetailsToUserSuccess = (data) => {
  return {
    type: constants.SEND_DETAILS_TO_USER_SUCCESS,
    data: data,
  };
};

export const sendDetailsToUserError = () => {
  return {
    type: constants.SEND_DETAILS_TO_USER_ERROR,
  };
};

export const setDepartDisableIndex = (data) => {
  return {
    type: constants.SET_DEPART_DISABLE_INDEX,
    data,
  };
};
export const createUserProject = (data) => {
  return {
    type: constants.CREATE_USER_PROJECT,
    data: data,
  };
};

export const createUserProjectSuccess = (data) => {
  return {
    type: constants.CREATE_USER_PROJECT_SUCCESS,
    data: data,
  };
};

export const createUserProjectError = () => {
  return {
    type: constants.CREATE_USER_PROJECT_ERROR,
  };
};

export const deleteUserProject = (data) => {
  return {
    type: constants.DELETE_USER_PROJECT,
    data,
  };
};

export const deleteUserProjectSuccess = (data) => {
  return {
    type: constants.DELETE_USER_PROJECT_SUCCESS,
    data,
  };
};

export const deleteUserProjectError = (data) => {
  return {
    type: constants.DELETE_USER_PROJECT_ERROR,
    data,
  };
};

export const setEditProjectPermissions = (data) => {
  return {
    type: constants.SET_USER_PROJECT_PERMISSIONS_IS_EDIT,
    data,
  };
};
export const updateUserProject = (data) => {
  return {
    type: constants.UPDATE_USER_PROJECT,
    data: data,
  };
};

export const updateUserProjectSuccess = (data) => {
  return {
    type: constants.UPDATE_USER_PROJECT_SUCCESS,
    data: data,
  };
};

export const updateUserProjectError = () => {
  return {
    type: constants.UPDATE_USER_PROJECT_ERROR,
  };
};

export const fetchAllUserProjects = (data) => {
  return {
    type: constants.FETCH_ALL_USER_PROJECTS,
    data: data,
  };
};

export const fetchAllUserProjectsSuccess = (data) => {
  return {
    type: constants.FETCH_ALL_USER_PROJECTS_SUCCESS,
    data: data,
  };
};

export const fetchAllUserProjectsError = (data) => {
  return {
    type: constants.FETCH_ALL_USER_PROJECTS_ERROR,
    data,
  };
};

export const fetchAllCurriculums = (data) => {
  return {
    type: constants.FETCH_ALL_CURRICULUM_STUDENTS,
    data: data,
  };
};

export const fetchAllCurriculumsSuccess = (data) => {
  return {
    type: constants.FETCH_ALL_CURRICULUM_STUDENTS_SUCCESS,
    data: data,
  };
};

export const fetchAllCurriculumsError = (data) => {
  return {
    type: constants.FETCH_ALL_CURRICULUM_STUDENTS_ERROR,
    data,
  };
};

export const updateCurriculum = (data) => {
  return {
    type: constants.UPDATE_CURRICULUM_STUDENT,
    data: data,
  };
};

export const updateCurriculumSuccess = (data) => {
  return {
    type: constants.UPDATE_CURRICULUM_STUDENT_SUCCESS,
    data: data,
  };
};

export const updateCurriculumError = () => {
  return {
    type: constants.UPDATE_CURRICULUM_STUDENT_ERROR,
  };
};

export const createCurriculum = (data) => {
  return {
    type: constants.CREATE_CURRICULUM_STUDENT,
    data: data,
  };
};

export const createCurriculumSuccess = (data) => {
  return {
    type: constants.CREATE_CURRICULUM_STUDENT_SUCCESS,
    data: data,
  };
};

export const createCurriculumError = (id) => {
  return {
    type: constants.CREATE_CURRICULUM_STUDENT_ERROR,
    id: id,
  };
};

export const isCurriculumAdding = (data) => {
  return {
    type: constants.ADD_CURRICULUM_STUDENT_IN_MANAGER,
    data,
  };
};

export const deleteCurriculum = (data) => {
  return {
    type: constants.DELETE_CURRICULUM_STUDENT,
    data: data,
  };
};

export const deleteCurriculumSuccess = (data) => {
  return {
    type: constants.DELETE_CURRICULUM_STUDENT_SUCCESS,
    data: data,
  };
};

export const deleteCurriculumError = (id) => {
  return {
    type: constants.DELETE_CURRICULUM_STUDENT_ERROR,
    id: id,
  };
};

export const setStorage = (data) => {
  return {
    type: constants.SET_USERS_MANAGER_STATE,
    data,
  };
};
