export const FETCH_NOTIFICATION_TEMPLATES_LIST = 'FETCH_NOTIFICATION_TEMPLATES_LIST'
export const FETCH_NOTIFICATION_TEMPLATES_LIST_SUCCESS = 'FETCH_NOTIFICATION_TEMPLATES_LIST_SUCCESS';
export const FETCH_NOTIFICATION_TEMPLATES_LIST_FAILURE = 'FETCH_NOTIFICATION_TEMPLATES_LIST_FAILURE';
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAILURE = "SEND_NOTIFICATION_FAILURE";

export const FETCH_NOTIFICATION_HISTORY = 'FETCH_NOTIFICATION_HISTORY';
export const FETCH_NOTIFICATION_HISTORY_FAILURE = "FETCH_NOTIFICATION_HISTORY_FAILURE";
export const FETCH_NOTIFICATION_HISTORY_SUCCESS = 'FETCH_NOTIFICATION_HISTORY_SUCCESS';
