import React, { useEffect } from "react";
import { Row, Col, Form, Input, Button, message } from "antd";
import axios from "axios";

const NotificationTemplate = ({ items, template_macros }) => {
  const { subject, title, body, _id } = items;
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      title: title || "",
      body: safeParseJSON(body),
    });
  }, [title, body, form]);

  const safeParseJSON = (str) => {
    if (!str) return "";
    try {
      return JSON.parse(str);
    } catch (error) {
      console.warn("Invalid JSON:", error);
      return str;
    }
  };

  const handleUpdate = async (values) => {
    try {
      const payload = {
        title: values.title,
        body:
          typeof values.body === "string"
            ? values.body
            : JSON.stringify(values.body),
      };

      const response = await axios.put(
        `/api/v2/admin/notification-templates/${_id}`,
        payload
      );

      if (response.status === 200) {
        message.success("Notification template updated successfully!");
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      console.error("Update Error:", error);
      message.error("Failed to update the notification template.");
    }
  };

  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Form layout="vertical" form={form} onFinish={handleUpdate}>
          <p>
            <b>{subject}</b>
          </p>
          <Form.Item
            label={<span style={{ fontWeight: "bold" }}>Title</span>}
            name="title"
            rules={[{ required: true, message: "Title is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={<span style={{ fontWeight: "bold" }}>Body</span>}
            name="body"
            rules={[{ required: true, message: "Body is required" }]}
          >
            <Input.TextArea rows={6} />
          </Form.Item>

          <Col span={24}>
            <Form.Item>
              <Button htmlType="submit">Submit</Button>
            </Form.Item>
          </Col>
        </Form>
      </Col>
    </Row>
  );
};

export default NotificationTemplate;
