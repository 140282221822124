import React, { useState } from "react";
import { Table, Input, Button, Modal } from "antd";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const HistoryTable = ({ emailsHistoryList }) => {
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [searchText, setSearchText] = useState({
    subject: "",
    body: "",
    first_name: "",
    last_name: "",
    sender: "",
    response: "",
    dateSent: "",
  });

  const handleSearch = (key, value) => {
    setSearchText((prev) => ({
      ...prev,
      [key]: value.toLowerCase(),
    }));
  };

  const handleViewColumn = (record) => {
    setSelectedRow(record);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
  };

  const allData = emailsHistoryList
    ?.map((item, index) => ({
      key: index,
      subject: item.subject || "N/A",
      first_name: item.recipient.first_name || "",
      last_name: item.recipient.last_name || "",
      body: item.body,
      sender: item.sender || "Unknown",
      response: item.response || "No response",
      dateSent: item.sendTimestamp
        ? dayjs(item.sendTimestamp).format("YYYY-MM-DD HH:mm:ss")
        : "N/A",
    }))
    .filter((item) =>
      Object.keys(searchText).every((key) =>
        item[key]?.toString().toLowerCase().includes(searchText[key])
      )
    );

  const commonColumns = [
    {
      title: (
        <div style={{ textAlign: "center", fontWeight: 700, fontSize: "11px" }}>
          ACTIONS
        </div>
      ),
      key: "action",
      fixed: "right",
      width: 120,
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            icon={<PlusOutlined />}
            className="add-doc-btn class-btn"
            size="middle"
            onClick={() => handleViewColumn(record)}
          >
            View
          </Button>
        </div>
      ),
    },
  ];

  const normalColumns = [
    {
      title: (
        <>
          <div style={{ fontWeight: 700, fontSize: "11px" }}>DATE SENT</div>
          <Input
            value={searchText.dateSent}
            onChange={(e) => handleSearch("dateSent", e.target.value)}
            size="small"
          />
        </>
      ),
      dataIndex: "dateSent",
      key: "dateSent",
      ellipsis: true,
      width: 180,
      render: (text) =>
        text !== "N/A" ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "N/A",
    },
    {
      title: (
        <>
          <div style={{ fontWeight: 700, fontSize: "11px" }}>FIRST NAME</div>
          <Input
            value={searchText.first_name}
            onChange={(e) => handleSearch("first_name", e.target.value)}
            size="small"
          />
        </>
      ),
      dataIndex: "first_name",
      key: "first_name",
      width: 150,
    },
    {
      title: (
        <>
          <div style={{ fontWeight: 700, fontSize: "11px" }}>LAST NAME</div>
          <Input
            value={searchText.last_name}
            onChange={(e) => handleSearch("last_name", e.target.value)}
            size="small"
          />
        </>
      ),
      dataIndex: "last_name",
      key: "last_name",
      width: 150,
    },
    {
      title: (
        <>
          <div style={{ fontWeight: 700, fontSize: "11px" }}>SENDER</div>
          <Input
            value={searchText.sender}
            onChange={(e) => handleSearch("sender", e.target.value)}
            size="small"
          />
        </>
      ),
      dataIndex: "sender",
      key: "sender",
      width: 150,
      ellipsis: true,
    },
    {
      title: (
        <>
          <div style={{ fontWeight: 700, fontSize: "11px" }}>RESPONSE</div>
          <Input
            value={searchText.response}
            onChange={(e) => handleSearch("response", e.target.value)}
            size="small"
          />
        </>
      ),
      dataIndex: "response",
      key: "response",
      width: 150,
    },
  ];

  const filteredData = allData;

  const htmlToText = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <>
      <Table
        className="special-table users-manager-table"
        columns={[...normalColumns, ...commonColumns]}
        dataSource={filteredData}
        pagination={{
          pageSize: 10,
          current: currentPage,
          onChange: (page) => setCurrentPage(page),
        }}
      />

      <Modal
        title="Email Details"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        style={{ top: 20 }}
      >
        {selectedRow && (
          <>
            <div style={{ marginBottom: "20px" }}>
              <label style={{ fontWeight: "bold" }}>Subject:</label>
              <Input value={selectedRow.subject} readOnly />
            </div>
            <div style={{ marginBottom: "10px" }}>
              <label style={{ fontWeight: "bold" }}>Body:</label>
              <div
                value={htmlToText(selectedRow.body)}
                style={{ height: "400px" }}
                dangerouslySetInnerHTML={{ __html: selectedRow.body }}
                readOnly
              />
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default HistoryTable;
