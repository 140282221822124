import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";

import HistoryTable from "./HistoryTable";
const EmailHistory = () => {
  const dispatch = useDispatch();
  const { emailsHistoryList } = useSelector(
    (state) => state.emailsManagerReducer
  );
  useEffect(() => {
    dispatch({ type: "FETCH_EMAILS_HISTORY" });
  }, [dispatch]);

  return (
    <div className="page-top-div">
      <span className="text-span">Email History</span>
      <Row className="production-manager-parent-row users-manager-parent-row">
        <Col span={24}>
          <HistoryTable emailsHistoryList={emailsHistoryList} />
        </Col>
      </Row>
    </div>
  );
};

export default EmailHistory;
