export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";
export const FETCH_EMAILS_TEMPLATES_LIST = 'FETCH_EMAILS_TEMPLATES_LIST';
export const FETCH_EMAILS_TEMPLATES_LIST_SUCCESS = 'FETCH_EMAILS_TEMPLATES_LIST_SUCCESS';
export const FETCH_EMAILS_TEMPLATES_LIST_FAILURE = 'FETCH_EMAILS_TEMPLATES_LIST_FAILURE';

export const FETCH_EMAILS_HISTORY = 'FETCH_EMAILS_HISTORY';
export const FETCH_EMAILS_HISTORY_FAILURE = "FETCH_EMAILS_HISTORY_FAILURE";
export const FETCH_EMAILS_HISTORY_SUCCESS = 'FETCH_EMAILS_HISTORY_SUCCESS';
