const FETCH_SUB_OBSERVATIONS = "FETCH_SUB_OBSERVATIONS";
const FETCH_SUB_OBSERVATIONS_SUCCESS = "FETCH_SUB_OBSERVATIONS_SUCCESS";
const FETCH_SUB_OBSERVATIONS_ERROR = "FETCH_SUB_OBSERVATIONS_ERROR";
const UPDATE_SUB_OBSERVATIONS = "UPDATE_SUB_OBSERVATIONS";
const UPDATE_SUB_OBSERVATIONS_SUCCESS = "UPDATE_SUB_OBSERVATIONS_SUCCESS";
const UPDATE_SUB_OBSERVATIONS_ERROR = "UPDATE_SUB_OBSERVATIONS_ERROR";
const DELETE_SUB_OBSERVATIONS = "DELETE_SUB_OBSERVATIONS";
const DELETE_SUB_OBSERVATIONS_SUCCESS = "DELETE_SUB_OBSERVATIONS_SUCCESS";
const DELETE_SUB_OBSERVATIONS_ERROR = "DELETE_SUB_OBSERVATIONS_ERROR";
const CREATE_SUB_OBSERVATIONS = "CREATE_SUB_OBSERVATIONS";
const CREATE_SUB_OBSERVATIONS_SUCCESS = "CREATE_SUB_OBSERVATIONS_SUCCESS";
const CREATE_SUB_OBSERVATIONS_ERROR = "CREATE_SUB_OBSERVATIONS_ERROR";
const UPDATE_ORDER_SUB_OBSERVATIONS = "UPDATE_ORDER_SUB_OBSERVATIONS";
const UPDATE_ORDER_SUB_OBSERVATIONS_SUCCESS =
  "UPDATE_ORDER_SUB_OBSERVATIONS_SUCCESS";
const UPDATE_ORDER_SUB_OBSERVATIONS_ERROR =
  "UPDATE_ORDER_SUB_OBSERVATIONS_ERROR";
const IS_ADDING_UPDATE = "IS_ADDING_UPDATE_SUB_OBSERVATIONS";
const SET_SUB_OBSERVATIONS_MANAGER_STATE = "SET_SUB_OBSERVATIONS_MANAGER_STATE";

export {
  FETCH_SUB_OBSERVATIONS,
  FETCH_SUB_OBSERVATIONS_SUCCESS,
  FETCH_SUB_OBSERVATIONS_ERROR,
  UPDATE_SUB_OBSERVATIONS,
  UPDATE_SUB_OBSERVATIONS_SUCCESS,
  UPDATE_SUB_OBSERVATIONS_ERROR,
  DELETE_SUB_OBSERVATIONS,
  DELETE_SUB_OBSERVATIONS_SUCCESS,
  DELETE_SUB_OBSERVATIONS_ERROR,
  CREATE_SUB_OBSERVATIONS,
  CREATE_SUB_OBSERVATIONS_SUCCESS,
  CREATE_SUB_OBSERVATIONS_ERROR,
  UPDATE_ORDER_SUB_OBSERVATIONS,
  UPDATE_ORDER_SUB_OBSERVATIONS_SUCCESS,
  UPDATE_ORDER_SUB_OBSERVATIONS_ERROR,
  IS_ADDING_UPDATE,
  SET_SUB_OBSERVATIONS_MANAGER_STATE,
};
