import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Form,
  Input,
  Button,
  Checkbox,
  message,
  List,
  Typography,
} from "antd";
import "react-quill/dist/quill.snow.css";
import { userTypeOptions } from "../UserManager/utils";
import { useDispatch, useSelector } from "react-redux";
// import { fetchAllUsers } from "../UserManager/redux/actions";
import { sendNotification } from "./redux/actions";
import { CheckCircleOutlined } from "@ant-design/icons";
import { fetchAllUserList } from "../UserManager/redux/actions";

const SendNotifications = () => {
  const dispatch = useDispatch();
  const allUserList = useSelector((state) => {
    return state.userManagerReducer.allUserList;
  });
  const { allNotifications } = useSelector(
    (state) => state.notificationManagerReducer
  );

  const { template_macros = [] } = allNotifications;
  const { allUsers } = useSelector((state) => state.userManagerReducer);
  const [form] = Form.useForm();
  const { allClasses } = useSelector((state) => state.classesReducer);

  const [assessorOptions, setAssessorOptions] = useState([]);
  const [selectClass, setSelectClass] = useState("");

  useEffect(() => {
    dispatch({ type: "FETCH_EMAILS_TEMPLATES_LIST" });
  }, [dispatch]);

  useEffect(() => {
    if (allClasses?.length > 0) {
      const updatedAssessorOptions = allClasses.map((item) => ({
        label: `${item.code}`,
        value: item._id,
      }));
      setAssessorOptions(updatedAssessorOptions);
    }
  }, [allClasses]);

  const handleSelectClass = (value) => {
    setSelectClass(value);
  };

  const [notificationBody, setNotificationBody] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  useEffect(() => {
    const toolbar = document.querySelector(".ql-toolbar");
    if (toolbar) {
      toolbar.style.backgroundColor = "#D0EDF7";
    }
  }, []);

  useEffect(() => {
    dispatch({ type: "FETCH_NOTIFICATION_TEMPLATES_LIST" });
  }, [dispatch]);

  const handleSelectChange = (value) => {
    setSelectedUsers([]);

    dispatch(fetchAllUserList({ search: { roles: value } }));
  };

  const handleSelectUser = (value) => {
    setSelectedUsers(value);
  };

  const handleCheckedBox = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);

    if (checked) {
      setSelectedUsers(allUserList?.map((user) => user.user_id));
    } else {
      setSelectedUsers([]);
    }
  };

  const onFinish = async (values) => {
    const payload = {
      user_ids: selectedUsers,
      title: values.subject,
      message: values.message,
      role: values.role,
    };

    await dispatch(sendNotification(payload));

    form.resetFields();
    setNotificationBody("");
    setSelectedUsers([]);
    setSelectAll(false);
  };

  const { TextArea } = Input;

  return (
    <div className="page-top-div">
      <span className="text-span">Send Notification</span>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ maxWidth: "100%" }}
      >
        <Row gutter={[8]} className="production-manager-parent-row">
          {template_macros.length > 0 && (
            <Col
              span={24}
              style={{
                paddingLeft: "0px",
                marginBottom: "30px",
                marginTop: "10px",
              }}
            >
              <List
                dataSource={template_macros}
                renderItem={(item) => (
                  <List.Item
                    style={{
                      fontSize: "15px",
                      padding: "0",
                      borderBottom: "none",
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "8px",
                    }}
                  >
                    <CheckCircleOutlined
                      style={{ color: "#1890ff", fontSize: "16px" }}
                    />
                    <Typography.Text>{item}</Typography.Text>
                  </List.Item>
                )}
              />
            </Col>
          )}

          <Col span={24}>
            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>
                  Select Class (Optional)
                </span>
              }
              name=""
              rules={[{ message: "Please select a user role!" }]}
              style={{ marginBottom: "10px" }}
            >
              <Select
                allowClear
                placeholder="Select Class"
                style={{ width: "100%" }}
                // onChange={handleSelectChange}
                // options={userTypeOptions}
                value={selectClass}
                onChange={handleSelectClass}
                options={assessorOptions}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>Select User Role</span>
              }
              name="role"
              rules={[{ message: "Please select a user role!" }]}
              style={{ marginBottom: "10px" }}
            >
              <Select
                allowClear
                placeholder="Select User Role"
                style={{ width: "100%" }}
                onChange={handleSelectChange}
                options={userTypeOptions}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="users"
              style={{ marginBottom: "10px" }}
              rules={[{ message: "Please select users!" }]}
            >
              <Col style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ fontWeight: "bold" }}>Select Users</span>
                <Checkbox
                  onChange={handleCheckedBox}
                  checked={selectAll}
                  style={{ fontWeight: "bold" }}
                >
                  Select All
                </Checkbox>
              </Col>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                value={selectedUsers}
                onChange={handleSelectUser}
                options={allUserList?.map((user) => ({
                  label: `${user.first_name} ${user?.last_name || ""}`,
                  value: user.user_id,
                }))}
                showSearch={true}
                optionFilterProp="label"
                filterOption={(input, option) =>
                  option?.label?.toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>Notification Subject</span>
              }
              name="subject"
              rules={[{ message: "Please enter a subject!" }]}
              style={{ marginBottom: "10px" }}
            >
              <Input placeholder="Enter notification subject" required />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <span style={{ fontWeight: "bold" }}>
                  Push Notification Body
                </span>
              }
              name="message"
              style={{ marginBottom: "20px" }}
              rules={[{ message: "Please enter a message!" }]}
            >
              <TextArea
                placeholder=""
                style={{ width: "100%", height: "250px" }}
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginBottom: "10px", marginTop: "8px" }}>
            <Button htmlType="submit">Send</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SendNotifications;
