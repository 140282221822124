import React, { useState } from "react";

import SubObservationTable from "./Table";
const SubObservations = () => {
  // const { user } = useSelector((state) => state.authReducer);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);

  // useEffect(() => {
  //   if (user?.permissions?.site_statuses?.add_edit) {
  //     setCanUpdate(true);
  //   }
  //   if (user?.permissions?.site_statuses?.delete) {
  //     setCanDelete(true);
  //   }
  // }, [user]);

  return (
    <div className="page-top-div">
      <span className="text-span">SUB OBSERVATION</span>
      <SubObservationTable canUpdate={canUpdate} canDelete={canDelete} />
    </div>
  );
};

export default SubObservations;
