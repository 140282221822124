import axios from "axios";
import { put, takeEvery, call } from "redux-saga/effects";
import { message as AntdAlert } from "antd";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import * as constants from "../constants";

export function* fetchEmailTemplates() {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v2/admin/email-templates/list`
    );
    const { success, data } = response.data;
    if (success) {
      const { email_templates = [], template_macros = [] } = data;

      yield put({
        type: constants.FETCH_EMAILS_TEMPLATES_LIST_SUCCESS,
        payload: { email_templates, template_macros },
      });
    }
  } catch (error) {
    yield put({ type: constants.FETCH_EMAILS_TEMPLATES_LIST_FAILURE, error });
  }
}

export function* handleSendEmail(action) {
  try {
    const { user_ids, subject, message, role } = action.payload;
    const response = yield call(
      axios.post,
      `${baseURL}/api/v2/users/send_email`,
      {
        user_ids,
        subject,
        message,
        role,
      }
    );

    if (response.data.success) {
      AntdAlert.success(response?.data?.message);

      yield put({ type: constants.SEND_EMAIL_SUCCESS });
    } else if (!response.data.success) {
      AntdAlert.error(response.data.success);
      yield put({ type: constants.SEND_EMAIL_FAILURE });
    } else {
      yield put({ type: constants.SEND_EMAIL_FAILURE });
    }
  } catch (error) {
    // yield put({ type: constants.SEND_EMAIL_FAILURE, error });
    console.error("Error sending email:", error);
    AntdAlert.error(error?.response?.data?.message || "Something went wrong!");
  }
}

export function* fetchEmailHistory() {
  try {
    const response = yield call(
      axios.get,
      `${baseURL}/api/v2/history/emailHistory/`
    );

    const { success, data } = response.data;

    if (success) {
      const { history } = data;

      yield put({
        type: constants.FETCH_EMAILS_HISTORY_SUCCESS,
        payload: history,
      });
    }
  } catch (error) {
    console.error("Error fetching email history:", error);
    yield put({ type: constants.FETCH_EMAILS_HISTORY_FAILURE, error });
  }
}

export function* watchEmailTemplates() {
  yield takeEvery(constants.FETCH_EMAILS_TEMPLATES_LIST, fetchEmailTemplates);
}
