import axios from "axios";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import * as constants from "../constants";
import { put, takeEvery, call } from "redux-saga/effects";
import { message as AntdAlert } from "antd";

export function* fetchNotificationTemplateList() {
  try {
    const response = yield call(
      axios.get,
      `${baseURL}/api/v2/admin/notification-templates/list`
    );

    if (response.data && response.data.success && response.data.data) {
      const { notification_templates = [], template_macros = [] } =
        response.data.data;
      yield put({
        type: constants.FETCH_NOTIFICATION_TEMPLATES_LIST_SUCCESS,
        payload: { notification_templates, template_macros },
      });
    } else {
      throw new Error("Invalid API response format");
    }
  } catch (error) {
    console.error("Error fetching notifications:", error);
    yield put({
      type: constants.FETCH_NOTIFICATION_TEMPLATES_LIST_FAILURE,
      error: error.message,
    });
  }
}

export function* handleSendNotification(action) {
  try {
    const { user_ids, title, message, role } = action.payload;
    const response = yield call(
      axios.post,
      `${baseURL}/api/v2/users/send_notification`,
      {
        user_ids,
        title,
        message,
        role,
      }
    );

    if (response.data.success) {
      AntdAlert.success(response?.data?.message);

      yield put({ type: constants.SEND_NOTIFICATION_SUCCESS });
    } else if (!response.data.success) {
      AntdAlert.error(response.data.success);
      yield put({ type: constants.SEND_NOTIFICATION_FAILURE });
    } else {
      yield put({ type: constants.SEND_NOTIFICATION_FAILURE });
    }
  } catch (error) {
    console.error("Error sending notification:", error);
    AntdAlert.error(error?.response?.data?.message || "Something went wrong!");
  }
}

export function* fetchNotificationHistory() {
  try {
    const response = yield call(
      axios.get,
      `${baseURL}/api/v2/history/notificationHistory`
    );

    const { success, data } = response.data;

    if (success) {
      const { history } = data;

      yield put({
        type: constants.FETCH_NOTIFICATION_HISTORY_SUCCESS,
        payload: history,
      });
    }
  } catch (error) {
    console.error("Error fetching notification history:", error);
    yield put({ type: constants.FETCH_NOTIFICATION_HISTORY_FAILURE, error });
  }
}

export function* watchNotificationTemplates() {
  yield takeEvery(
    constants.FETCH_NOTIFICATION_TEMPLATES_LIST,
    fetchNotificationTemplateList
  );
}
