import * as constants from "./constants";

export const fetchNotificationTemplateList = (data) => {
  return {
    type: constants.FETCH_NOTIFICATION_TEMPLATES_LIST,
    data,
  };
};

export const sendNotification = (payload) => {
  return {
    type: constants.SEND_NOTIFICATION,
    payload,
  };
};

export const fetchNotificationHistory = (payload) => {
  return {
    type: constants.FETCH_NOTIFICATION_HISTORY,
    payload,
  };
};
