import axios from "axios";
import { message as AntdAlert } from "antd";
import { put, takeEvery } from "redux-saga/effects";
import * as actions from "../actions";
import * as authActions from "../../../login/redux/actions";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import { errorHandler } from "../../../../components/unauthorizeHandler";
import * as constants from "../constants";

export function* handleCreateUser({ data }) {
  try {
    const fd = new FormData();
    for (const [key, value] of Object.entries(data)) {
      // if (key === 'permissions' && value !== null) {
      //   Object.entries(value).map((entry) => {
      //     const [rep, obj] = entry;
      //     Object.entries(obj).map((mainObj) => {
      //       const [objectName, objectValue] = mainObj;
      //       Object.entries(objectValue).map((lastObj) => {
      //         const [name, value] = lastObj;
      //         fd.append(`permissions[${rep}][${objectName}][${name}]`, value);
      //       });
      //     });
      //   });
      // } else if (key === 'field_values') {
      //   Object.entries(value).map((entry) => {
      //     fd.append(`field_values[][id]`, entry[0]);
      //     fd.append(`field_values[][value]`, entry[1]);
      //   });
      // } else if (
      //   key.includes('kinship_attributes') ||
      //   key.includes('supervisor_attributes')
      // ) {
      //   let [first, second, third] = key.split('_');
      //   fd.append(`${first}_${second}[${third}]`, value);
      // } else

      if (
        [
          // 'addedStudents',
          // 'addedGuardians',
          // 'deletedStudents',
          // 'deletedGuardians',
          "added_curriculum",
          "deleted_curriculum",
          "roles",
        ].includes(key)
      ) {
        value.forEach((el) => {
          fd.append(`${key}[]`, el);
        });
      } else if (value !== null && value !== undefined) {
        fd.append(key, value);
      }
    }
    const response = yield axios.post(
      `${baseURL}/api/v2/admin/users/create`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const { message, success } = response.data;

    if (success) {
      AntdAlert.success(message);
      const { user } = response.data?.data;
      yield put(actions.createUserSuccess(user));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.createUserError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
    AntdAlert.error(error?.response?.data?.message || "Something went wrong!");

    yield put(actions.createUserError());
  }
}

export function* handleUpdateUser({ data }) {
  try {
    const fd = new FormData();
    for (const [key, value] of Object.entries(data)) {
      // if (key === 'permissions') {
      //   fd.append('permissions', JSON.stringify(value));
      // } else if (key === 'field_values') {
      //   Object.entries(value).map((entry) => {
      //     fd.append(`field_values[][id]`, entry[0]);
      //     fd.append(`field_values[][value]`, entry[1]);
      //   });
      // } else if (
      //   key.includes('kinship_attributes') ||
      //   key.includes('supervisor_attributes')
      // ) {
      if (
        [
          "addedStudents",
          "addedGuardians",
          "deletedStudents",
          "deletedGuardians",
          "added_curriculum",
          "deleted_curriculum",
          "addedClasses",
          "deletedClasses",
          "roles",
        ].includes(key)
      ) {
        if (value.length > 0) {
          value.forEach((el) => {
            fd.append(`${key}[]`, el);
          });
        }
        // }
      } else if (value !== null && value !== undefined) {
        fd.append(key, value);
      }
    }
    const response = yield axios.put(
      `${baseURL}/api/v2/admin/users/update/${data.id}`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    const { message } = response.data;
    if (response.data?.data?.user) {
      AntdAlert.success(message);
      yield put(actions.updateUserSuccess(response.data?.data?.user));
      let user = JSON.parse(localStorage.getItem("user"));
      const { _id } = response.data?.data?.user;
      if (user?._id === _id) {
        yield put(authActions.setUser(response.data?.data?.user));
        localStorage.setItem(
          "user",
          JSON.stringify({ ...user, ...response.data?.data?.user })
        );
      }
    } else if (!response.data?.data?.user) {
      AntdAlert.error(message);
      yield put(actions.updateUserError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.updateUserError());
    AntdAlert.error(error?.response?.data?.message || "Something went wrong!");
    console.log(error);
  }
}

export function* handleUpdateUserPermissions({ data }) {
  const { permissions, id } = data;
  try {
    const response = yield axios.patch(`${baseURL}/users/update_user`, {
      permissions,
      id,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateUserPermissionsSuccess(response.data.user));
      let user = JSON.parse(localStorage.getItem("user"));
      const { _id } = response.data.user;
      if (user?._id === _id) {
        yield put(authActions.setUser(response.data.user));
        localStorage.setItem(
          "user",
          JSON.stringify({ ...user, ...response.data.user })
        );
      }
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.updateUserPermissionsError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateUserSettings({ data }) {
  const { loadingObj } = data;

  let obj = {};
  if (loadingObj?.browserNotLoading) {
    obj = { browserNotLoading: false };
  }
  if (loadingObj?.emailNotLoading) {
    obj = { emailNotLoading: false };
  }
  if (loadingObj?.mobileNotLoading) {
    obj = { mobileNotLoading: false };
  }

  try {
    const response = yield axios.patch(`${baseURL}/users/update_user`, {
      ...data.data,
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(
        actions.updateUserSettingsSuccess({
          data: response.data.user,
          loadingObj: obj,
        })
      );
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      errorHandler(response.data);
      yield put(actions.updateUserSettingsError());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchUserDetailsUtils() {
  try {
    const response = yield axios.get(`${baseURL}/users/user_details`);
    const { success } = response.data;

    console.log("detail", response);
    if (success) {
      yield put(actions.fetchUserDetailsUtilsSuccess(response.data.data));
    }
    errorHandler(response.data);
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchAllUsers({ data }) {
  const { userType, search, ...rest } = data;
  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  try {
    const response = yield axios.get(
      `${baseURL}/api/v2/admin/users/filtered-users`,
      {
        params: {
          ...rest,
          ...(Object.keys(search).length > 0 && { ...search }),
        },
      }
    );
    const { success } = response.data;
    if (success) {
      const { users, totalUsers } = response.data.data;

      yield put(
        actions.fetchAllUsersSuccess({
          total_users: totalUsers,
          users,
        })
      );
    }
    errorHandler(response.data);
  } catch (error) {
    AntdAlert.error("Something went wrong!");
    yield put(actions.fetchAllUsersError());
    console.log(error);
  }
}

export function* handleDeleteUser({ data }) {
  const { _id } = data;
  try {
    const response = yield axios.delete(`${baseURL}/api/v2/admin/users/${_id}`);
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteUserSuccess(response.data.data.user));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.deleteUserError(response.data.data.user));
    }
  } catch ({ response }) {
    yield put(actions.deleteUserError(response.data.data.user));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleAddDepartment({ data }) {
  try {
    const response = yield axios.post(`${baseURL}/departments`, {
      ...data,
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.saveDepartmentSuccess(response.data.data));
      yield put(actions.setDepartDisableIndex(data.departments.length - 1));
    } else if (!success) {
      errorHandler(response.data);
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.saveDepartmentError());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchUser({ data }) {
  const { id } = data;

  // let user = JSON.parse(localStorage.getItem('user'));
  // yield put(actions.fetchUserSuccess(user));
  // return;
  try {
    const response = yield axios.get(`${baseURL}/api/v2/admin/users/${id}`);
    // const { success } = response.data.data;
    if (response.data?.data?.user) {
      yield put(actions.fetchUserSuccess(response.data?.data?.user));
      let user = JSON.parse(localStorage.getItem("user"));
      const { _id } = response.data?.data?.user;
      if (user?._id === _id) {
        yield put(authActions.setUser(response.data?.data?.user));
        const { auth_token } = JSON.parse(localStorage.getItem("user"));
        let updatedUser = {
          ...response.data?.data?.user,
          auth_token,
          tenant: user.tenant,
        };
        localStorage.setItem("user", JSON.stringify(updatedUser));
      }
    } else {
      yield put(actions.updateUserError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.updateUserError());
    console.log(error);
    AntdAlert.error("Something went wrong!");
  }
}

export function* handleSendDetailsToUser({ data }) {
  try {
    const response = yield axios.post(`${baseURL}/users/login_details`, {
      email: data,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      // const { permissions, ...res } = response.data.data;
      yield put(actions.sendDetailsToUserSuccess({})); // ...res, ...permissions
    } else if (!success) {
      AntdAlert.error(message);
      errorHandler(response.data);
      yield put(actions.sendDetailsToUserError());
    }
  } catch ({ response }) {
    const { status } = response;
    if (status == 500 || status == 404) {
      AntdAlert.error("Something went wrong!");
      yield put(actions.sendDetailsToUserError());
    }
  }
}
export function* handleCreateProject({ data }) {
  try {
    const response = yield axios.post(`${baseURL}/user_npos`, {
      ...data,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createUserProjectSuccess(response.data.data));
      const { user_id } = response.data.data;
      yield put(authActions.fetchSidebarProject({ user_id }));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createUserProjectError());
      errorHandler(response);
    }
  } catch (error) {
    console.log(error);
  }
}
export function* handleUpdateUserProject({ data }) {
  const { updateData, setExpandedRowKeys, setEditingKey } = data;
  const { id, ...rest } = updateData;
  try {
    const response = yield axios.patch(`${baseURL}/user_npos/${id}`, {
      ...rest,
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateUserProjectSuccess(response.data.data));
      setExpandedRowKeys([]);
      setEditingKey("");
      const { user_id } = updateData;
      yield put(authActions.fetchSidebarProject({ user_id }));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.updateUserProjectError(response.data.data));

      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateUserProjectError());
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}
export function* handleDeleteUserProject({ data }) {
  const { shouldLSUpdate, user_id } = data;
  // try {
  const response = yield axios.delete(`${baseURL}/user_npos/${data.id}`);
  const { success, message } = response.data;
  if (success) {
    AntdAlert.success(message);
    let resData = { ...response.data.data, shouldLSUpdate };
    yield put(actions.deleteUserProjectSuccess({ ...resData }));
    yield put(authActions.fetchSidebarProject({ user_id }));
  } else if (!success) {
    errorHandler(response.data);
    yield put(actions.deleteUserProjectError());
  }
  // } catch ({ response }) {
  //   yield put(actions.deleteUserProjectError());
  //   AntdAlert.error("Something went wrong!");
  //   console.log(response);
  // }
}

export function* handleFetchAllUserProjects({ data }) {
  try {
    let params = {};
    if (data?.search) {
      params = {
        ...params,
        ...data.search,
        email: data.email,
      };
    }
    if (data?.sortedInfo?.order) {
      params = {
        ...params,
        sort:
          data.sortedInfo.field === "npo_id"
            ? "npos.name"
            : data.sortedInfo.field,
        order: data?.sortedInfo?.order === "ascend" ? "asc" : "desc",
      };
    }
    const response = yield axios.get(
      `${baseURL}/api/v2/admin/users/filtered-users`,
      {
        params: params,
      }
    );
    const { message } = response.data;
    if (response.data?.data?.teachers?.data) {
      // AntdAlert.success(message);
      let params = {
        projects: response.data?.data?.teachers?.data,
        user_id: data.user_id,
      };
      yield put(actions.fetchAllUserProjectsSuccess({ ...params }));
    } else if (!response.data?.data?.teachers?.data) {
      errorHandler(response.data);
      yield put(actions.fetchAllUserProjectsError());
    }
  } catch ({ response }) {
    yield put(actions.fetchAllUserProjectsError());
  }
}

export function* handleFetchCurriculums({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v2/admin/users/curriculums/${data.user_id}`
    );
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchAllCurriculumsSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchAllCurriculumsError());
    AntdAlert.error("Something went wrong!");
    console.log(error);
  }
}

export function* handleCreateCurriculum({ data }) {
  const { curriculum_id, user_id, progress_status, onSuccess } = data;
  try {
    const response = yield axios.post(
      `${baseURL}/api/v2/admin/users/curriculums`,
      { curriculum_id, user_id, progress_status }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createCurriculumSuccess(response.data.data));
      onSuccess();
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createCurriculumError(data._id));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    console.log(response);
    AntdAlert.error(response.data.message || "Something went wrong!");
    yield put(actions.createCurriculumError());
  }
}

export function* handleUpdateCurriculum({ data }) {
  const { onSuccess, ...rest } = data;
  try {
    const response = yield axios.patch(
      `${baseURL}/api/v2/admin/users/curriculums`,
      rest
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateCurriculumSuccess(response.data.data));
      onSuccess();
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateCurriculumError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateCurriculumError(response.data.data));
    AntdAlert.error(response.data?.message || "Something went wrong!");
    console.log(response);
  }
}

export function* handleDeleteCurriculum({ data }) {
  const { id, handleSuccess } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v2/admin/users/curriculums/${id}`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteCurriculumSuccess(response.data.data));
      handleSuccess();
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteCurriculumError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteCurriculumError());
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleFetchAllUsersList({ data }) {
  try {
    const role = data?.search?.roles;
    if (!role) {
      AntdAlert.error("Role is required to fetch users");
      return;
    }
    const response = yield axios.get(`${baseURL}/api/v2/users?role=${role}`);
    if (response.data?.data?.users) {
      yield put({
        type: constants.FETCH_ALL_USERS_LIST_SUCCESS,
        payload: response.data.data.users,
      });
    } else {
      yield put(actions.updateUserError());
      AntdAlert.error("No users found");
    }
  } catch (error) {
    yield put(actions.updateUserError());
    console.error(error);
    AntdAlert.error("Something went wrong while fetching users!");
  }
}

export function* watcherStudentsCurriculumSaga() {
  yield takeEvery(constants.CREATE_CURRICULUM_STUDENT, handleCreateCurriculum);
  yield takeEvery(constants.UPDATE_CURRICULUM_STUDENT, handleUpdateCurriculum);
  yield takeEvery(
    constants.FETCH_ALL_CURRICULUM_STUDENTS,
    handleFetchCurriculums
  );
  yield takeEvery(constants.DELETE_CURRICULUM_STUDENT, handleDeleteCurriculum);
}
