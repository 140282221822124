import * as constants from "./constants";

const initialState = {
  allNotifications: {
    notification_templates: [],
    template_macros: [],
    notificationHistoryList: [],
  },
  error: null,
};

const notificationManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_NOTIFICATION_TEMPLATES_LIST_SUCCESS:
      return {
        ...state,
        allNotifications: action.payload,
        error: null,
      };

    case constants.FETCH_NOTIFICATION_TEMPLATES_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    //add
    case constants.SEND_NOTIFICATION:
      return {
        ...state,
        // createUserLoading: true,
      };

    case constants.FETCH_NOTIFICATION_HISTORY_SUCCESS:
      return {
        ...state,
        notificationHistoryList: action.payload,
      };

    default:
      return state;
  }
};

export default notificationManagerReducer;
