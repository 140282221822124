import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";

import HistoryTable from "./HistoryTable";
const NotificationHistory = () => {
  const dispatch = useDispatch();
  const { notificationHistoryList } = useSelector(
    (state) => state.notificationManagerReducer
  );

  useEffect(() => {
    dispatch({ type: "FETCH_NOTIFICATION_HISTORY" });
  }, [dispatch]);

  return (
    <div className="page-top-div">
      <span className="text-span">Notification History</span>
      <Row className="production-manager-parent-row users-manager-parent-row">
        <Col span={24}>
          <HistoryTable notificationHistoryList={notificationHistoryList} />
        </Col>
      </Row>
    </div>
  );
};

export default NotificationHistory;
