import * as constants from "./constants";

export const fetchSubObservations = (data) => {
  return {
    type: constants.FETCH_SUB_OBSERVATIONS,
    data,
  };
};

export const fetchSubObservationsSuccess = (data) => {
  return {
    type: constants.FETCH_SUB_OBSERVATIONS_SUCCESS,
    data: data,
  };
};

export const fetchSubObservationsError = (data) => {
  return {
    type: constants.FETCH_SUB_OBSERVATIONS_ERROR,
    data: data,
  };
};

export const updateSubObservations = (data) => {
  return {
    type: constants.UPDATE_SUB_OBSERVATIONS,
    data: data,
  };
};

export const updateSubObservationsSuccess = (data) => {
  return {
    type: constants.UPDATE_SUB_OBSERVATIONS_SUCCESS,
    data: data,
  };
};

export const updateSubObservationsError = () => {
  return {
    type: constants.UPDATE_SUB_OBSERVATIONS_ERROR,
  };
};

export const updateSubObservationOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_SUB_OBSERVATIONS,
    data: data,
  };
};

export const updateSubObservationOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_SUB_OBSERVATIONS_SUCCESS,
    data: data,
  };
};

export const updateSubObservationOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_SUB_OBSERVATIONS_ERROR,
  };
};

export const deleteSubObservation = (data) => {
  return {
    type: constants.DELETE_SUB_OBSERVATIONS,
    data: data,
  };
};

export const deleteSubObservationsSuccess = (data) => {
  return {
    type: constants.DELETE_SUB_OBSERVATIONS_SUCCESS,
    data: data,
  };
};

export const deleteSubObservationsError = () => {
  return {
    type: constants.DELETE_SUB_OBSERVATIONS_ERROR,
  };
};

export const createSubObservations = (data) => {
  return {
    type: constants.CREATE_SUB_OBSERVATIONS,
    data: data,
  };
};

export const createSubObservationsSuccess = (data) => {
  return {
    type: constants.CREATE_SUB_OBSERVATIONS_SUCCESS,
    data: data,
  };
};

export const createSubObservationsError = (id) => {
  return {
    type: constants.CREATE_SUB_OBSERVATIONS_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};

export const setStorage = (data) => {
  return {
    type: constants.SET_SUB_OBSERVATIONS_MANAGER_STATE,
    data,
  };
};
