import { combineReducers } from "redux";
import userManagerReducer from "../modules/UserManager/redux/userManagerReducer";
import departEmailsReducer from "../modules/DepartmentEmails/redux/departEmailsReducer";
import departmentReducer from "../modules/ActivityTypes/redux/activityTypesReducer";
import clientManagerReducer from "../modules/ClientsManager/redux/clientManagerReducer";
import salesReducer from "../modules/Sales/redux/salesReducer";
import salesGraphReducer from "../modules/Sales/redux/graphReducer";
import projectReducer from "../modules/Projects/redux/reducer";
import authReducer from "../modules/login/redux/authReducer";
import productionReducer from "../modules/Production/redux/productionReducer";
import secondLevelReducer from "../modules/Production/redux/secondLevelReducer";
import thirdLevelReducer from "../modules/Production/redux/thirdLevelReducer";
import fourthLevelReducer from "../modules/Production/redux/fourthLevelReducer";
import salesSecondLevelReducer from "../modules/Sales/redux/salesSecondLevelReducer";
import projectStatusReducer from "../modules/ProjectStatuses/redux/projectStatusesReducer";
import npoSetupReducer from "../modules/NpoSetup/redux/reducer";
import quoteStatusReducer from "../modules/QuoteStatuses/redux/reducer";
import documentFieldReducer from "../modules/DefaultDocumentFields/redux/documentFieldReducer";
import interventionTypesReducer from "../modules/InterventionTypesManager/redux/reducer";
import documentControlReducer from "../modules/DocumentControl/redux/documentControlReducer";
import sitesReducer from "../modules/Sites/redux/reducer";
import imsRevisionsReducer from "../modules/Sites/tableSection/classes/redux/reducer";
import imsDistributionReducer from "../modules/Sites/tableSection/distributionTable/redux/distributionReducer";
import leadsReducer from "../modules/curriculum-management/redux/reducer";
import projectRevisionsReducer from "../modules/Projects/tableSection/revisions/redux/revisionsReducer";
import projectDistributionReducer from "../modules/Projects/tableSection/distributionTable/redux/distributionReducer";
import notificationsReducer from "../modules/container/notifications/notificationsRedux/notificationReducer";
import formSetupReducer from "../modules/FormSetupManager/redux/reducer";
import valueAddedReducer from "../modules/valueAddedServices/redux/reducer";
import programmeTypesReducer from "../modules/ProgrammeTypes/redux/reducer";
import npoRegionsReducer from "../modules/NpoRegions/redux/reducer";
import npoStatusReducer from "../modules/NpoStatuses/redux/reducer";
import siteStatusReducer from "../modules/SiteStatuses/redux/reducer";
import contactTypesReducer from "../modules/contactTypes/redux/reducer";
import productTypesReducer from "../modules/productTypes/redux/reducer";
import coachStatusReducer from "../modules/CoachStatuses/redux/reducer";
import coachTypesReducer from "../modules/CoachTypes/redux/reducer";
import leadStatusReducer from "../modules/LeadStatuses/redux/reducer";
import learnerCampsReducer from "../modules/LearnerCamps/redux/reducer";
import learnerTypesReducer from "../modules/LearnerTypes/redux/reducer";
import learnersReducer from "../modules/learners/redux/reducer";
import coachesReducer from "../modules/coaches/redux/reducer";
import learningActivitiesReducer from "../modules/LearningActivities/redux/reducer";
import learningActivityStickersReducer from "../modules/LearningActivities/stickers/redux/reducer";
import stickersReducer from "../modules/stickers/redux/reducer";
import slotsReducer from "../modules/Slots/redux/reducer";
import roomsReducer from "../modules/rooms/redux/reducer";
import commentsReducer from "../modules/comments/redux/reducer";
import classesReducer from "../modules/Classes/redux/reducer";
import classSessionsReducer from "../modules/ClassSessions/redux/reducer";
import subObservationsReducers from "../modules/SubObservations/redux/reducer";
import streamsReducer from "../modules/streams/redux/reducer";
// import classesLearnersReducer from '../modules/Classes/tableSection/learners/redux/reducer';
import curriculumStudentReducer from "../modules/UserManager/redux/curriculumStudentReducer";
import emailsManagerReducer from "../modules/EmailsManagement/redux/EmailsManagerReducer";
import notificationManagerReducer from "../modules/NotificationManagement/redux/NotificationsManagerReducer";
export default combineReducers({
  documentControlReducer,
  interventionTypesReducer,
  documentFieldReducer,
  projectStatusReducer,
  userManagerReducer,
  emailsManagerReducer,
  notificationManagerReducer,
  departEmailsReducer,
  departmentReducer,
  clientManagerReducer,
  salesReducer,
  authReducer,
  productionReducer,
  secondLevelReducer,
  thirdLevelReducer,
  fourthLevelReducer,
  salesGraphReducer,
  salesSecondLevelReducer,
  npoSetupReducer,
  quoteStatusReducer,
  sitesReducer,
  imsRevisionsReducer,
  imsDistributionReducer,
  leadsReducer,
  // classesLearnersReducer,
  projectReducer,
  projectRevisionsReducer,
  projectDistributionReducer,
  notificationsReducer,
  formSetupReducer,
  valueAddedReducer,
  programmeTypesReducer,
  npoRegionsReducer,
  npoStatusReducer,
  siteStatusReducer,
  contactTypesReducer,
  productTypesReducer,
  coachStatusReducer,
  coachTypesReducer,
  leadStatusReducer,
  learnerCampsReducer,
  learnerTypesReducer,
  learnersReducer,
  coachesReducer,
  learningActivitiesReducer,
  learningActivityStickersReducer,
  stickersReducer,
  slotsReducer,
  roomsReducer,
  commentsReducer,
  classesReducer,
  classSessionsReducer,
  subObservationsReducers,
  streamsReducer,
  curriculumStudentReducer,
});
