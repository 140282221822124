import React, { useEffect } from "react";
import { Col, Row, List, Typography } from "antd";
import NotificationTemplate from "./NotificationTemplate";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleOutlined } from "@ant-design/icons";
const NotificationForm = () => {
  const dispatch = useDispatch();
  const { allNotifications } = useSelector(
    (state) => state.notificationManagerReducer
  );
  const { notification_templates = [], template_macros = [] } =
    allNotifications;

  useEffect(() => {
    dispatch({ type: "FETCH_NOTIFICATION_TEMPLATES_LIST" });
  }, [dispatch]);

  return (
    <>
      <Row align="middle" gutter={[16, 24]}>
        <Col span={24}>
          <div className="heading-col">
            <span className="heading-span">Notification Templates</span>
          </div>
        </Col>
        {template_macros.length > 0 && (
          <Col span={24} style={{ paddingLeft: "20px" }}>
            <List
              dataSource={template_macros}
              renderItem={(item) => (
                <List.Item
                  style={{
                    fontSize: "15px",
                    padding: "0",
                    borderBottom: "none",
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "8px",
                  }}
                >
                  <CheckCircleOutlined
                    style={{ color: "#1890ff", fontSize: "16px" }}
                  />
                  <Typography.Text>{item}</Typography.Text>
                </List.Item>
              )}
            />
          </Col>
        )}
        <Col span={24}>
          {notification_templates.map((items) => (
            <NotificationTemplate key={items._id} items={items} />
          ))}
        </Col>
      </Row>
    </>
  );
};

export default NotificationForm;
