import * as constants from "./constants";

export const fetchEmailsTemplateList = (data) => {
  return {
    type: constants.FETCH_EMAILS_TEMPLATES_LIST,
    data,
  };
};

export const sendEmail = (payload) => {
  return {
    type: constants.SEND_EMAIL,
    payload,
  };
};

export const fetchEmailHistory = (payload) => {
  return {
    type: constants.FETCH_EMAILS_HISTORY,
    payload,
  };
};
