import React from 'react'
// import './index.less';
import { useLocation } from 'react-router-dom';
import { Row , Col} from 'antd';
import EmailForm from './EmailForm';

const Emails = () => {
  const { pathname } = useLocation();
  let isTeacher = pathname.includes('teachers');
  let userType = isTeacher ? 'Teachers' : 'Students';

  return (
    <div className='page-top-div'>
    <span className='text-span'>Email Manager</span>
    <Row className='production-manager-parent-row users-manager-parent-row'>
      <Col span={24}><EmailForm
        yearsLevelOption={[]}
        userType={userType}
        isTeacher={isTeacher}
      /></Col>
    </Row>
  </div>
  )
}

export default Emails;


