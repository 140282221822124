import React, { useEffect } from "react";
import { Layout } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
// import Sale from '../Sales';
// import Sites from '../Sites';
// import Learners from '../learners';
import Production from "../Production";
import SidebarComponent from "./sidebar";
import UserManager from "../UserManager";
// import ProjectStatuses from '../ProjectStatuses';
// import DocumentControl from '../DocumentControl';
import LeadStatuses from "../LeadStatuses";
// import DefaultDocumentFields from '../DefaultDocumentFields';
// import ClientsManager from '../ClientsManager';
import NpoSetup from "../NpoSetup";
import Slots from "../Slots";
// import UserTypes from '../UserTypes';
// import Departments from '../ActivityTypes';
import "./index.less";
import UserDetails from "../UserManager/userDetails";
// import ClientDetails from '../ClientsManager/clientDetails';
import axios from "axios";
import { baseURL } from "../../globalUtils/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../login/redux/actions";
// import LearnerCamps from '../LearnerCamps';
// import LearnerTypes from '../LearnerTypes';
// import CoachTypes from '../CoachTypes';
// import CoachStatuses from '../CoachStatuses';
import ContactTypes from "../contactTypes";
// import SiteStatuses from '../SiteStatuses';
// import NpoStatuses from '../NpoStatuses';
// import NpoRegions from '../NpoRegions';
// import ProgrammeTypes from '../ProgrammeTypes';
import NpoDetail from "../NpoSetup/detail/index.jsx";
// import InterventionTypes from '../InterventionTypesManager';
// import InterventionTypesManager from '../InterventionTypesManager/create';
import FormSetupManager from "../FormSetupManager";
import FormSetupManagerDetails from "../FormSetupManager/create";
import ProductTypes from "../productTypes";
import QuoteStatuses from "../QuoteStatuses";
import ValueAddedServices from "../valueAddedServices";
import CurriculumManagement from "../curriculum-management";
import FirstStep from "../curriculum-management/curriculum-pages/firstStep";
import SecondStep from "../curriculum-management/curriculum-pages/secondStep";
import ThirdStep from "../curriculum-management/curriculum-pages/ThirdStep/index";
import ImportZip from "../importZip/index.jsx";
import LearningActivities from "../LearningActivities/index.jsx";
import LearningActivitiesDetail from "../LearningActivities/detail/index.jsx";
import EnrollStudents from "../enrollStudents/index.jsx";
import ImportStickers from "../importStickers/index.jsx";
import Stickers from "../stickers/index.jsx";
import ImportProfileZip from "../importProfileZip/index.jsx";
import ImportObservations from "../importObservation/index.jsx";
import SlotDetail from "../Slots/detail/index.jsx";
import ImportRooms from "../importRooms/index.jsx";
import ImportSlots from "../importSlots/index.jsx";
import Rooms from "../rooms/index.jsx";
import Comments from "../comments/index.jsx";
import SubObservations from "../SubObservations/index.jsx";
import Classes from "../Classes/index.jsx";
import ClassDetail from "../Classes/detail/index.jsx";
import ClassesSessions from "../ClassSessions/index.jsx";
import ClassesSessionsDetail from "../ClassSessions/detail/index.jsx";
import Streams from "../streams/index.jsx";
import Emails from "../EmailsManagement/index.js";
import EmailForm from "../EmailsManagement/EmailForm.js";
import SendEmails from "../EmailsManagement/SendEmails.jsx";
import EmailHistory from "../EmailsManagement/EmailHistory.jsx";
import Notifications from "../NotificationManagement/index.js";
import SendNotifications from "../NotificationManagement/SendNotifications.jsx";
import NotificationHistory from "../NotificationManagement/NotificationHistory.jsx";
// import DocumentTypesManager from '../DocumentsTypesManager';
// import TemplateCreation from '../DocumentsTypesManager/documentTemplateCreate/index.jsx';
// import Projects from '../Projects';

const LayoutComponent = () => {
  const { Content } = Layout;
  const { user } = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const validateUser = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/v2/users/check_user_token`
        );
        const { success } = response.data;
        if (success) {
          dispatch(
            actions.loginUserSuccess({
              user: response.data.data,
              navigate,
              shouldNavigate: false,
            })
          );
        }
        if (!success) {
          dispatch(actions.logoutUserSuccess(response.data.data));
        }
      } catch (error) {
        console.log(error);
      }
    };
    // validateUser();
  }, []);

  return (
    <Layout className="most-parent-layout">
      <Layout className="site-layout">
        <SidebarComponent />
        <Content className="layout-content site-layout-background custom-textarea">
          <Routes>
            <Route path="/settings/email-manager" element={<Emails />} />
            <Route path="/settings/send-emails" element={<SendEmails />} />
            <Route path="/settings/email-history" element={<EmailHistory />} />
            {/* <Route path='/email/new' element={<EmailForm />} /> */}
            <Route
              path="/settings/notification-manager"
              element={<Notifications />}
            />
            <Route
              path="/settings/send-notifications"
              element={<SendNotifications />}
            />
            <Route
              path="/settings/notification-history"
              element={<NotificationHistory />}
            />
            <Route path="/import-rooms" element={<ImportRooms />} />
            <Route path="/import-slots" element={<ImportSlots />} />
            <Route path="/import-zip" element={<ImportZip />} />
            <Route path="/enroll-students" element={<EnrollStudents />} />
            <Route path="/import-stickers" element={<ImportStickers />} />
            <Route path="/stickers" element={<Stickers />} />
            <Route
              path="/import-observations"
              element={<ImportObservations />}
            />
            <Route
              path="/import-profile-images"
              element={<ImportProfileZip />}
            />
            {/* {user?.permissions?.leads?.view && ( */}
            <Route
              path="/curriculum-management"
              element={<CurriculumManagement />}
            />
            <Route
              path="/curriculum-management/create"
              element={<FirstStep />}
            />
            <Route
              path="/curriculum-management/update/:id"
              element={<FirstStep />}
            />
            <Route
              path="/curriculum-management/second-step"
              element={<SecondStep />}
            />
            <Route
              path="/curriculum-management/second-step/:id"
              element={<SecondStep />}
            />
            <Route
              path="/curriculum-management/milestones/:id"
              element={<ThirdStep />}
            />

            {/* )} */}
            <Route path="/settings/users-manager" element={<UserManager />} />
            {/* {user?.permissions?.users_manager?.view && ( */}
            <>
              <Route path="/settings/users-manager" element={<UserManager />} />
              <Route
                path="/settings/users-manager/update/:id"
                element={<UserDetails />}
              />
              {/* {user?.permissions?.users_manager?.add_edit && ( */}
              <>
                <Route
                  path="/settings/users-manager/create"
                  element={<UserDetails />}
                />
              </>
              {/* )} */}
            </>
            {/* )} */}

            <Route path="/books" element={<NpoSetup />} />
            <Route path="/books/update/:id" element={<NpoDetail />} />
            <Route path="/books/create" element={<NpoDetail />} />

            <Route path="/slots" element={<Slots />} />
            <Route path="/slots/update/:id" element={<SlotDetail />} />
            <Route path="/slots/create" element={<SlotDetail />} />
            <Route path="/rooms" element={<Rooms />} />
            <Route path="/comments" element={<Comments />} />
            <Route path="/subobservations" element={<SubObservations />} />
            <Route path="/classes" element={<Classes />} />
            <Route path="/classes/update/:id" element={<ClassDetail />} />
            <Route path="/classes/create" element={<ClassDetail />} />
            <Route path="/classes-sessions" element={<ClassesSessions />} />
            <Route
              path="/class-session/details/:id"
              element={<ClassesSessionsDetail />}
            />

            <Route
              path="/learning-activities"
              element={<LearningActivities />}
            />
            <Route
              path="/learning-activities/update/:id"
              element={<LearningActivitiesDetail />}
            />
            <Route
              path="/learning-activities/create"
              element={<LearningActivitiesDetail />}
            />
            <Route path="/streams" element={<Streams />} />

            {user?.permissions?.contact_type?.view && (
              <Route path="/settings/contact-type" element={<ContactTypes />} />
            )}
            {user?.permissions?.product_type?.view && (
              <Route path="/settings/product-type" element={<ProductTypes />} />
            )}
            {user?.permissions?.lead_statuses?.view && (
              <Route
                path="/settings/lead-statuses"
                element={<LeadStatuses />}
              />
            )}
            {user?.permissions?.quote_statuses?.view && (
              <Route
                path="/settings/quote-statuses"
                element={<QuoteStatuses />}
              />
            )}

            {user?.permissions?.form_manager?.view && (
              <>
                <Route
                  path="/settings/form-manager/value-added-services"
                  element={<ValueAddedServices />}
                />
                <Route
                  path="/settings/form-manager/form-setup"
                  element={<FormSetupManager />}
                />
                <Route
                  path="/settings/form-manager/form-setup/:id"
                  element={<FormSetupManagerDetails />}
                />
                {user?.permissions?.form_manager?.add_edit && (
                  <>
                    <Route
                      path="/settings/form-manager/form-setup/create"
                      element={<FormSetupManagerDetails />}
                    />
                  </>
                )}
              </>
            )}
            <Route path="/profile" element={<UserDetails />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
