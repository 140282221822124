import * as constants from "./constants";

const initialState = {
  allSubObservations: [],
  fetchingLoading: true,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
  search: {},
};

const subObservationsReducers = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_SUB_OBSERVATIONS:
      return { ...state, updateLoading: true };

    case constants.CREATE_SUB_OBSERVATIONS_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allSubObservations: [action.data, ...state.allSubObservations],
        isAdding: false,
      };

    case constants.CREATE_SUB_OBSERVATIONS_ERROR:
      return { ...state, updateLoading: false };

    case constants.UPDATE_ORDER_SUB_OBSERVATIONS:
      return { ...state, fetchingLoading: true };

    case constants.UPDATE_ORDER_SUB_OBSERVATIONS_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allSubObservations: Array.isArray(action.data) ? action.data : [],
      };

    case constants.UPDATE_ORDER_SUB_OBSERVATIONS_ERROR:
      return { ...state, fetchingLoading: false };

    case constants.FETCH_SUB_OBSERVATIONS:
      return { ...state, fetchingLoading: true };

    case constants.FETCH_SUB_OBSERVATIONS_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allSubObservations: Array.isArray(action.data) ? action.data : [],
      };

    case constants.FETCH_SUB_OBSERVATIONS_ERROR:
      return { ...state, fetchingLoading: false };

    case constants.UPDATE_SUB_OBSERVATIONS:
      return { ...state, updateLoading: true };

    case constants.UPDATE_SUB_OBSERVATIONS_SUCCESS:
      return {
        ...state,
        allSubObservations: state.allSubObservations.map((el) =>
          el._id === action.data._id ? { ...el, ...action.data } : el
        ),
        updateLoading: false,
      };

    case constants.UPDATE_SUB_OBSERVATIONS_ERROR:
      return { ...state, updateLoading: false };

    case constants.DELETE_SUB_OBSERVATIONS:
      return { ...state, updateLoading: true };

    case constants.DELETE_SUB_OBSERVATIONS_SUCCESS:
      return {
        ...state,
        allSubObservations: state.allSubObservations.filter(
          (el) => el._id !== action.data
        ),
        updateLoading: false,
      };

    case constants.DELETE_SUB_OBSERVATIONS_ERROR:
      return { ...state, updateLoading: false };

    case constants.IS_ADDING_UPDATE:
      return { ...state, isAdding: action.data };

    case constants.SET_SUB_OBSERVATIONS_MANAGER_STATE:
      const newState = { ...state, ...action.data };
      localStorage.setItem(
        "subObservationManagerState",
        JSON.stringify(newState)
      );
      return newState;

    default:
      return state;
  }
};

export default subObservationsReducers;
